<template>
  <div class="content">
    For any information, please join us by email at:
    <p style="text-align: center"><br /><b>contact[at]bcomputd.com</b></p>
  </div>
</template>

<script>
export default {
  name: "Contact",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding: 5% 0;
}
</style>
