<template>
  <h1>404</h1>
  <p>Page not Found</p>
</template>

<script>
export default {
  name: "Not Found",

  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding: 5% 0;
}
</style>
